import styled from 'styled-components';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import { COLOR } from 'constants/COLOR';
import { useEffect, useState } from 'react';
import { Icon } from 'components/images';

const Component = styled.div`
  background-color: ${COLOR.LIGHT_GRAY};
  height: 320px;
  overflow: hidden;
  ${({ theme }) =>
    theme.media.S(`
    height: 400px;
  `)}
`;

const Slider = styled.div<{ lang: string }>`
  position: relative;
  width: 1064px;
  height: fit-content;
  margin: 70px auto;
  ${({ theme, lang }) =>
    theme.media.M(`
    width: 592px;
    margin: ${lang === 'ko' ? '72px auto' : '36px auto'};
  `)}
  ${({ theme, lang }) =>
    theme.media.S(`
    width: 312px;
    margin: ${lang === 'ko' || lang === 'ja' ? '100px auto' : '60px auto'};
  `)}
`;

const Button = styled.button`
  position: absolute;
  width: 40px;
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  border: 0;
  transition: 0.2s;
  cursor: pointer;
  font-size: 2rem;
  &.prev {
    left: 0;
  }
  &.next {
    right: 0;
  }
  ${({ theme }) =>
    theme.media.S(`
    width: 24px;
    height: 24px;
    margin-top: 24px;
  `)}
`;
const FocusContainer = styled.div`
  width: 1064px;
  height: 160px;
  ${({ theme }) =>
    theme.media.M(`
    width: 592px;
    height: 214px;
  `)}
  ${({ theme }) =>
    theme.media.S(`
    width: 312px;
  `)}
`;

const Reviews = styled.div<{ focusId?: number; isLastSlide?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 800%;
  left: -100%;
  ${({ focusId }) => focusId && `left: calc(${focusId} * -100%);`}
  top: 0;
  ${({ isLastSlide }) => (isLastSlide ? '' : 'transition: 0.3s;')}
  ${({ theme }) =>
    theme.media.M(`
    margin-top: 16px;
  `)}
`;

const Review = styled.div<{ isActive?: boolean }>`
  width: 100%;
  pointer-events: none;
  position: relative;
  ${({ isActive }) => (isActive ? '' : 'opacity: 0.3;')}
`;

const DescContainer = styled.div`
  width: 468px;
  margin: 0 auto;
  text-align: center;
  ${({ theme }) =>
    theme.media.M(`
    width: 416px;
  `)}
  ${({ theme }) =>
    theme.media.S(`
    width: 240px;
  `)}
`;

const Person = styled.div`
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 16px;

  ${({ theme }) =>
    theme.media.SM(`
    font-size: 14px;
  `)}
`;

const Desc = styled.div`
  margin: 12px 0;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  .red {
    font-weight: 600;
    color: ${COLOR.DARK};
  }
  ${({ theme }) =>
    theme.media.SM(`
    font-size: 14px;
  `)}
`;

export const UserReviewBox = ({ pathLang }: { pathLang: string }) => {
  // ================ 0. Init ================
  // States
  const [isMove, setIsMove] = useState(true);
  const [focusId, setFocusId] = useState(2);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const { t } = useTranslation('landing');

  // Constants
  const slideInterval = 6000;
  const transitionTime = 300;

  const renderingText = [
    {
      img: '/images/landing2/userIllust3.svg',
      person: t('UserReviewBox.person-0', { lng: pathLang }),
      content: t('UserReviewBox.content-0', { lng: pathLang }),
    },
    {
      img: '/images/landing2/userIllust4.svg',
      person: t('UserReviewBox.person-1', { lng: pathLang }),
      content: t('UserReviewBox.content-1', { lng: pathLang }),
    },
    {
      img: '/images/landing2/userIllust1.svg',
      person: t('UserReviewBox.person-2', { lng: pathLang }),
      content: t('UserReviewBox.content-2', { lng: pathLang }),
    },
    {
      img: '/images/landing2/userIllust2.svg',
      person: t('UserReviewBox.person-3', { lng: pathLang }),
      content: t('UserReviewBox.content-3', { lng: pathLang }),
    },
  ];

  const userReviewArr = [...renderingText, ...renderingText];

  // ================ 1. Functions ================
  // show next slide(actual slides : [2, 3, 4, 5])
  const handleNext = () => {
    if (focusId >= 5) {
      setFocusId(() => 6);
      // substitution after transition time
      setTimeout(() => {
        setIsLastSlide(() => true);
        setFocusId(() => 2);
      }, transitionTime);
    } else {
      setIsLastSlide(() => false);
      setFocusId(cur => (cur + 1) % 8);
    }
  };
  // show previous slide(actual slides : [2, 3, 4, 5])
  const handleBefore = () => {
    if (focusId <= 2) {
      setFocusId(() => 1);
      // substitution after transition time
      setTimeout(() => {
        setIsLastSlide(() => true);
        setFocusId(() => 5);
      }, transitionTime);
    } else {
      setIsLastSlide(() => false);
      setFocusId(cur => (cur - 1) % 8);
    }
  };

  // ================ 2. Hooks ================
  // Auto slide
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (isMove) {
        handleNext();
      }
    }, slideInterval);
    return () => clearTimeout(delayDebounceFn);
  }, [focusId, isMove]);

  return (
    <Component>
      <Slider lang={pathLang}>
        <Button
          className="prev"
          aria-label="Show Previous Review"
          onMouseEnter={() => setIsMove(false)}
          onMouseLeave={() => setIsMove(true)}
          onClick={handleBefore}
        >
          <Icon size={40} mbSize={24} src="/icons/outline/i_left.svg" />
        </Button>
        <FocusContainer>
          <Reviews
            focusId={focusId}
            isLastSlide={isLastSlide}
            onMouseEnter={() => setIsMove(false)}
            onMouseLeave={() => setIsMove(true)}
          >
            {userReviewArr.map((item: any, idx: number) => (
              <Review key={`${item.person}-${idx}`} isActive={idx === focusId}>
                <DescContainer>
                  <Person dangerouslySetInnerHTML={{ __html: item.person }} />
                  <div className="flex flex-row items-center">
                    <Image src="/icons/outline/i_quotation_mark1.svg" width={18} height={13} alt="quote start icon" />
                  </div>
                  <Desc dangerouslySetInnerHTML={{ __html: item.content }} />
                  <div className="flex flex-row items-center justify-end">
                    <Image src="/icons/outline/i_quotation_mark2.svg" width={18} height={13} alt="quote end icon" />
                  </div>
                </DescContainer>
              </Review>
            ))}
          </Reviews>
        </FocusContainer>
        <Button
          className="next"
          aria-label="Show Next Review"
          onMouseEnter={() => setIsMove(false)}
          onMouseLeave={() => setIsMove(true)}
          onClick={handleNext}
        >
          <Icon size={40} mbSize={24} src="/icons/outline/i_right.svg" />
        </Button>
      </Slider>
    </Component>
  );
};

// Pages that should not be accessible had a user not been logged in
export const signedInArr = [
  '/proofread',
  '/paraphrasing',
  '/translation',
  '/promo',
  '/account',
  '/support',
  '/company/about',
  '/plan/payment/v1',
  '/plan',
  '/faq',
  '/setting',
];

// Pages that should not be accessible had a user been logged in

export const stayLandingArr = [
  '/auth/google/sign-in',
  '/auth/google/sign-up',
  '/auth/kakao',
  '/auth/reset-password',
  '/auth/sign-in',
  '/auth/sign-up',
  '/auth/whale',
  '/welcome',
  '/private/api',
  '/private/api/doc',
];

export const langSeparateLandingArr = [
  '/',
  '/ko',
  '/ja',
  '/cn',
  '/es',

  '/about-us',
  '/ko/about-us',
  '/ja/about-us',
  '/cn/about-us',
  '/es/about-us',

  '/grammar-check',
  '/ko/grammar-check',
  '/ja/grammar-check',
  '/cn/grammar-check',
  '/es/grammar-check',

  '/pricing',
  '/ja/pricing',
  '/ko/pricing',
  '/cn/pricing',
  '/es/pricing',

  '/paraphrase',
  '/ko/paraphrase',
  '/ja/paraphrase',
  '/cn/paraphrase',
  '/es/paraphrase',

  '/translate',
  '/ko/translate',
  '/ja/translate',
  '/cn/translate',
  '/es/translate',

  '/word-counter',
  '/ko/word-counter',
  '/ja/word-counter',
  '/cn/word-counter',
  '/es/word-counter',

  '/resume-builder',
  '/ko/resume-builder',
  '/ja/resume-builder',
  '/cn/resume-builder',
  '/es/resume-builder',

  '/education',
  '/ko/education',
  '/ja/education',
  '/cn/education',
  '/es/education',

  '/business',
  '/ko/business',
  '/ja/business',
  '/cn/business',
  '/es/business',

  '/spell-check',
  '/ko/spell-check',
  '/ja/spell-check',
  '/cn/spell-check',
  '/es/spell-check',
];
